/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'solicitation';
const getSolicitationList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getSolicitationDetails = (id) => api.get(`${prefix}/${id}`);
const postSolicitation = (data) => api.post(prefix, data);
const postSolicitationBatch = (data) => api.post(`${prefix}/batch`, data);
const putSolicitation = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteSolicitation = (id, reason) =>
  api.delete(`${prefix}/${id}`, { reason });
const postSolicitationCancel = (data) => api.post(`${prefix}/cancel`, data);
const getSolicitationAddress = (query) =>
  api.get(
    `${prefix}/address?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const postSolicitationExport = (data) => api.post(`${prefix}/export`, data);
const postSolicitationImport = (data) =>
  api.post(`${prefix}/import`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const getSolicitationPhotos = (id) => api.get(`${prefix}/${id}/photo`);
const putSolicitationRouting = (id, routing_id) =>
  api.put(`${prefix}/${id}/routing`, { routing_id });

const postSolicitationGenerateRouting = (id, ref_id, target) =>
  api.post(`${prefix}/${id}/routing`, { ref_id, target });

const postSolicitationRestore = (id) => api.post(`${prefix}/${id}/restore`, {});
const putMoveMultiplePassengers = (data) => api.put(`${prefix}/routing-batch`, data);

export {
  getSolicitationList,
  getSolicitationDetails,
  postSolicitation,
  postSolicitationBatch,
  putSolicitation,
  deleteSolicitation,
  getSolicitationAddress,
  postSolicitationCancel,
  postSolicitationExport,
  postSolicitationImport,
  getSolicitationPhotos,
  putSolicitationRouting,
  postSolicitationGenerateRouting,
  postSolicitationRestore,
  putMoveMultiplePassengers
};
