import {
  getUserList,
  getUserDetails,
  putUser,
  postUser,
  deleteUser,
  postUserPhoto,
  getRoleList,
  getUserCharterAvailable,
  getUserCharterList,
  deleteUserCharterList,
  postUserCharterList
} from '../api';

const list = (params) => getUserList(params);
const show = (id) => getUserDetails(id);
const update = (id, data) => putUser(id, data);
const store = (data) => postUser(data);
const destroy = (id) => deleteUser(id);
const updatePhoto = (id, data) => postUserPhoto(id, data);
const listRoles = (params) => getRoleList(params);
const charterAvailable = (id) => getUserCharterAvailable(id);
const listCharter = (id) => getUserCharterList(id);
const deleteCharter = (id, data) => deleteUserCharterList(id, data);
const updateCharter = (id, data) => postUserCharterList(id, data)

export const userService = {
  list,
  show,
  update,
  store,
  destroy,
  updatePhoto,
  listRoles,
  charterAvailable,
  listCharter,
  deleteCharter,
  updateCharter
};
