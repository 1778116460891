/* eslint-disable import/no-cycle */
import api from './index';

const prefix = 'user';
const getUserList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`,
  );
const getUserDetails = (id) => api.get(`${prefix}/${id}`);
const postUser = (data) => api.post(prefix, data);
const putUser = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteUser = (id) => api.delete(`${prefix}/${id}`);
const postUserPhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const getUserCharterList = (id) => api.get(`${prefix}/${id}/charter`)
const postUserCharterList = (id, data) => api.post(`${prefix}/${id}/charter`, data)
const deleteUserCharterList = (id, data) => api.delete(`${prefix}/${id}/charter/${data}`)
const getUserCharterAvailable = (id) => api.get(`${prefix}/${id}/charter-available`)

export {
  getUserList,
  getUserDetails,
  postUser,
  putUser,
  deleteUser,
  postUserPhoto,
  getUserCharterList,
  postUserCharterList,
  deleteUserCharterList,
  getUserCharterAvailable
};
